import { render, staticRenderFns } from "./ProjectSidebar.vue?vue&type=template&id=1b5e97c9&scoped=true&"
import script from "./ProjectSidebar.vue?vue&type=script&lang=js&"
export * from "./ProjectSidebar.vue?vue&type=script&lang=js&"
import style0 from "./ProjectSidebar.vue?vue&type=style&index=0&id=1b5e97c9&lang=scss&scoped=true&"
import style1 from "./ProjectSidebar.vue?vue&type=style&index=1&id=1b5e97c9&lang=scss&scoped=true&"
import style2 from "./ProjectSidebar.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b5e97c9",
  null
  
)

export default component.exports