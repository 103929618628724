<template>
  <b-sidebar
    id="sidebar-task-handler"
    @change="
      (val) => {
        $emit('update:is-project-sidebar-active', val);
      }
    "
    sidebar-class="sidebar-lg"
    @shown="openSidebar()"
    @hidden="clearSidebarData()"
    :visible="isProjectSidebarActive"
    bg-variant="white"
    shadow
    width="700px"
    backdrop
    right
  >
    <div>
      <b-overlay :show="loading" rounded="sm">
        <b-row v-if="projectData != null">
          <b-col cols="12">
            <b-card>
              <span
                @click="activeTab = 1"
                :class="{
                  activeMenu: activeTab === 1,
                  passiveMenu: activeTab !== 1,
                }"
              >
                <i class="fa-solid fa-table-columns mr-25 "></i> Overview
              </span>
              <span
                v-if="$Can('projects_files')"
                @click="activeTab = 2"
                :class="{
                  activeMenu: activeTab === 2,
                  passiveMenu: activeTab !== 2,
                }"
              >
                <i class="fa-solid fa-list-check mr-25"></i> Files
              </span>
              <span
                v-if="this.projectData.clientId != null && $Can('pcard_additional')"
                @click="activeTab = 3"
                :class="{
                  activeMenu: activeTab === 3,
                  passiveMenu: activeTab !== 3,
                }"
              >
                <i class="fa-solid fa-folder-tree mr-25"></i> Additional
              </span>

              <div v-if="activeTab == 1">
                <div class="cardClass">
                  <div class="d-flex justify-content-between mt-1 mb-2">
                    <div class="w-35">
                      <button
                        v-if="$Can('projects_archive')"
                        :style="{
                          backgroundColor: projectData.archived ? '#8d8c8c' : '#2dc034',
                          color: '#FFFFFF',
                        }"
                        style=" border: none; padding: 5px; cursor: pointer; width:100%"
                        id="archive-control"
                      >
                        {{ projectData.archived ? 'Archived' : 'Active' }}
                      </button>
                      <b-popover target="archive-control" triggers="blur focus" variant="primary" placement="left">
                        <div class="d-flex flex-column">
                          <button @click="archiveProject(false)" class="mb-25" style="padding:5px 25px;  border:none;background-color:#2dc034; color:#FFFFFF">
                            Active
                          </button>
                          <button @click="archiveProject(true)" class="mb-25" style="padding:5px 25px; border:none;background-color:#8d8c8c; color:#FFFFFF">
                            Archived
                          </button>
                        </div>
                      </b-popover>
                    </div>

                    <div class="w-35">
                      <button
                        :style="{
                          backgroundColor: getStatusBgColor(projectData.status),
                          color: getSectionColor(projectData.status),
                        }"
                        style=" border: none; padding: 5px; cursor: pointer; width:100%"
                        id="status-popover"
                      >
                        {{ projectData.status || 'N/A' }}
                      </button>
                      <b-popover target="status-popover" triggers="focus" variant="primary" placement="left">
                        <div class="d-flex flex-column">
                          <button v-for="status in deckOptions" @click="handleDeckChange(status.name)" class="mb-25" style="padding:5px 25px;  border:none;color:white" :style="`background-color: ${status.bgColor} `">
                            {{ status.name }}
                          </button>
                        </div>
                      </b-popover>
                    </div>
                  </div>

                  <div class="projectTitle mb-1" @click="handleClickName" v-if="!isNameEditing">
                    {{ projectData.name }}
                  </div>
                  <b-input v-else class="mb-1" autofocus v-model="projectData.name" @blur="updateProjectName" @focus="initialDescription = projectData.name" @keyup.enter="updateProjectNameEnter" />

                  <hr />

                  <div>
                    <div class="cardClass">
                      <div class="subTitle">Project Description</div>

                      <div class="description" @click="handleClickDescription(projectData)" v-if="!isDescriptionEditing">
                        <span v-html="projectData.description || 'No Description'"> </span>
                      </div>

                      <div v-else>
                        <quill-editor id="quil-content" style="background-color: white;" v-model="editedDescription" :options="descriptionOption" />

                        <div id="description-toolbar" class="d-flex justify-content-end">
                          <button class="ql-bold"></button>
                          <button class="ql-underline"></button>
                          <button class="ql-background" value="#fdff32"></button>
                          <button class="ql-list" value="ordered"></button>
                          <button class="ql-list" value="bullet"></button>
                          <button class="ql-link"></button>

                          <b-button @click="updateProjectDescription" variant="primary" style="width: 60px;">
                            Update
                          </b-button>

                          <b-button @click="cancelDescriptionEdit" variant="danger" class="ml-25" style="width: 60px;">
                            Cancel
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="$Can('projects_tasks')">
                    <div class="cardClass">
                      <div class="subTitle">Tasks</div>

                      <div class="todo-header">
                        <div class="header-cell w-55">Task Name</div>
                        <div class="header-cell w-15">Assignee</div>
                        <div class="header-cell w-30" style="white-space: nowrap;">
                          Due Date
                        </div>
                      </div>

                      <div v-for="(task, index) in projectData.tasks" :key="index">
                        <div class="task-content">
                          <div class="todo-cell w-4 d-flex justify-content-end">
                            <i v-if="task.sub_tasks.length > 0" :class="['fa-solid', 'fa-caret-' + (task.isExpanded ? 'down' : 'right'), 'mr-1 text-dark']" style="font-size: 1rem; cursor:pointer" @click="toggleExpand(task)"></i>
                            <i v-else :class="['fa-solid', 'fa-caret-right', 'mr-1']" style="font-size: 1rem; cursor:pointer; visibility: hidden"></i>
                            <div class="custom-checkbox">
                              <span class="checkbox-icon" :class="{ checked: task.isCompleted }" @click="updateTaskIsCompleted(task)"></span>
                            </div>
                          </div>

                          <div class="todo-cell w-51 hover-background" style="border-left:none; cursor: pointer; transition: background-color 0.2s;" @click="handleTaskClick(task)">
                            <div class="task-name">
                              <div class="w-100">{{ task.taskName }}</div>
                            </div>
                          </div>
                          <div class="todo-cell w-15  hover-background">
                            <div class="w-100">
                              <span v-if="task.userTitle != 'Assign This Task'">
                                <b-dropdown :text="task.userTitle" variant="link" block no-caret>
                                  <template #button-content>
                                    <div class="d-flex align-items-center">
                                      <b-avatar v-if="task.userTitle != 'Assign This Task'" :text="getAvatarText(task.userTitle)" variant="light-primary" size="28" class="mr-25" style="align-self: flex-start;"></b-avatar>
                                      <b-avatar v-else text="?" variant="light-info" size="28" style="align-self: flex-start;"></b-avatar>
                                    </div>
                                  </template>

                                  <b-dropdown-item @click="updateTaskAssigneUser(task, user)" style="width: 225px;" v-for="user in users" :key="user.id">
                                    {{ user.fullName }}
                                  </b-dropdown-item>

                                  <b-dropdown-divider />
                                  <b-dropdown-item
                                    variant="danger"
                                    @click="
                                      updateTaskAssigneUser(task, {
                                        fullName: 'Assign This Task',
                                        id: null,
                                      })
                                    "
                                    >Remove from project</b-dropdown-item
                                  >
                                </b-dropdown>
                              </span>

                              <span v-else class="text-info">
                                <b-dropdown text="Assign This Task" variant="link" block no-caret>
                                  <template #button-content>
                                    <div class="d-flex align-items-center">
                                      <b-avatar v-if="task.userTitle != 'Assign This Task'" :text="getAvatarText(task.userTitle)" variant="light-primary" size="28" class="mr-25" style="align-self: flex-start;"></b-avatar>
                                      <b-avatar v-else text="?" variant="light-info" size="28" style="align-self: flex-start;"></b-avatar>
                                      <span class="text-dark" style="margin-left: auto; margin-right: auto;">{{ task.userTitle }}</span>
                                    </div>
                                  </template>

                                  <b-dropdown-item @click="updateTaskAssigneUser(task, user)" style="width: 225px;" v-for="user in users" :key="user.id">
                                    {{ user.fullName }}
                                  </b-dropdown-item>
                                </b-dropdown>
                              </span>
                            </div>
                          </div>

                          <!-- <div class="todo-cell w-10  hover-background" @click="handleTaskClick(task)">
    <div class="w-100">
      <span>
        <div class="d-flex align-items-center">
          <b-avatar :text="getAvatarText(name)" v-for="(name, index) in task.additionalUserTitles" :key="index" variant="light-primary" size="20" class="mr-25" style="align-self: flex-start;" v-if="index < 2"></b-avatar>
        </div>
      </span>
    </div>
  </div> -->

                          <div class="todo-cell w-30 hover-background">
                            <b-form-datepicker
                              :id="'due-date-' + task.id.toString()"
                              placeholder="Add due date"
                              v-model="task.dueDate"
                              :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                              }"
                              @input="updateTaskDueDate(task.id, task.dueDate)"
                              locale="en"
                              label-help=""
                              :start-weekday="1"
                              :hide-header="true"
                              style="border:none; background-color: transparent;"
                            >
                            </b-form-datepicker>
                          </div>

                          <!-- <div class="todo-cell w-12 hover-background">
    <button
      :style="{
        backgroundColor: getSectionBgColor(task.priority),
        color: getSectionColor(task.priority),
      }"
      style=" border: none; padding: 5px; cursor: pointer; width:100%"
      :id="'priority-controls-' + task.id.toString()"
    >
      {{ task.priority || 'N/A' }}
    </button>
    <b-popover :target="'priority-controls-' + task.id.toString()" :show.sync="popoverShow[task.id]" triggers="focus" variant="primary" placement="left">
      <div class="d-flex flex-column">
        <button @click="updateTaskPriority(task, 'Critical')" class="mb-25" style="padding:5px 25px;  border:none" :style="{ backgroundColor: getSectionBgColor('Critical'), color: getSectionColor('Critical') }">Critical</button>
        <button @click="updateTaskPriority(task, 'High')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('High'), color: getSectionColor('High') }">High</button>
        <button @click="updateTaskPriority(task, 'Medium')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('Medium'), color: getSectionColor('Medium') }">Medium</button>
        <button @click="updateTaskPriority(task, 'Low')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('Low'), color: getSectionColor('Low') }">Low</button>
        <button @click="updateTaskPriority(task, 'Not a Priority')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('Not a Priority'), color: getSectionColor('Not a Priority') }">Not a Priority</button>
      </div>
    </b-popover>
  </div> -->
                        </div>

                        <!-- SUB TASKSSSSSSSSS -->
                        <div v-for="(subtask, index) in task.sub_tasks" :key="index">
                          <div v-if="task.isExpanded" class="subtask-content">
                            <div class="todo-cell w-4  d-flex justify-content-end">
                              <i class="fa-solid fa-caret-right" style="font-size: 1rem;  visibility: hidden; margin-right:2.2rem"></i>
                              <div class="custom-checkbox">
                                <span class="checkbox-icon" :class="{ checked: subtask.isCompleted }" @click="updateTaskIsCompleted(subtask)"></span>
                              </div>
                            </div>

                            <div class="todo-cell w-51 hover-background" style="border-left:none;  cursor: pointer; transition: background-color 0.2s;" @click="handleTaskClick(subtask)">
                              <div class="task-name">
                                <div>{{ subtask.taskName }}</div>
                              </div>
                            </div>

                            <div class="todo-cell w-15  hover-background">
                              <div class="w-100">
                                <span v-if="subtask.userTitle != 'Assign This Task'">
                                  <b-dropdown :text="subtask.userTitle" variant="link" block no-caret>
                                    <template #button-content>
                                      <div class="d-flex align-items-center">
                                        <b-avatar v-if="subtask.userTitle != 'Assign This Task'" :text="getAvatarText(subtask.userTitle)" variant="light-primary" size="28" class="mr-25" style="align-self: flex-start;"></b-avatar>
                                        <b-avatar v-else text="?" variant="light-info" size="28" style="align-self: flex-start;"></b-avatar>
                                      </div>
                                    </template>

                                    <b-dropdown-item @click="updateTaskAssigneUser(subtask, user)" style="width: 225px;" v-for="user in users" :key="user.id">
                                      {{ user.fullName }}
                                    </b-dropdown-item>

                                    <b-dropdown-divider />
                                    <b-dropdown-item
                                      variant="danger"
                                      @click="
                                        updateTaskAssigneUser(subtask, {
                                          fullName: 'Assign This Task',
                                          id: null,
                                        })
                                      "
                                      >Remove from project</b-dropdown-item
                                    >
                                  </b-dropdown>
                                </span>

                                <span v-else class="text-info">
                                  <b-dropdown text="Assign This Task" variant="link" block no-caret>
                                    <template #button-content>
                                      <div class="d-flex align-items-center">
                                        <b-avatar v-if="subtask.userTitle != 'Assign This Task'" :text="getAvatarText(subtask.userTitle)" variant="light-primary" size="28" class="mr-25" style="align-self: flex-start;"></b-avatar>
                                        <b-avatar v-else text="?" variant="light-info" size="28" style="align-self: flex-start;"></b-avatar>
                                        <span class="text-dark" style="margin-left: auto; margin-right: auto;">{{ subtask.userTitle }}</span>
                                      </div>
                                    </template>

                                    <b-dropdown-item @click="updateTaskAssigneUser(subtask, user)" style="width: 225px;" v-for="user in users" :key="user.id">
                                      {{ user.fullName }}
                                    </b-dropdown-item>
                                  </b-dropdown>
                                </span>
                              </div>
                            </div>

                            <!-- <div class="todo-cell w-10  hover-background">
      <div class="w-100">
        <span>
          <div class="d-flex align-items-center">
            <b-avatar :text="getAvatarText(name)" v-for="(name, index) in subtask.additionalUserTitles" :key="index" variant="light-primary" size="20" class="mr-25" style="align-self: flex-start;" v-if="index < 2"></b-avatar>
          </div>
        </span>
      </div>
    </div> -->

                            <div class="todo-cell w-30 hover-background">
                              <b-form-datepicker
                                :id="'due-date-' + subtask.id.toString()"
                                placeholder="Add due date"
                                v-model="subtask.dueDate"
                                :date-format-options="{
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                }"
                                @input="updateTaskDueDate(subtask.id, subtask.dueDate)"
                                locale="en"
                                label-help=""
                                :start-weekday="1"
                                :hide-header="true"
                                style="border:none; background-color: transparent;"
                              >
                              </b-form-datepicker>
                            </div>

                            <!-- <div class="todo-cell w-12 hover-background">
      <button
        :style="{
          backgroundColor: getSectionBgColor(subtask.priority),
          color: getSectionColor(subtask.priority),
        }"
        style=" border: none; padding: 5px; cursor: pointer; width:100%"
        :id="'date-subtask-controls-' + subtask.id.toString()"
      >
        {{ subtask.priority || 'N/A' }}
      </button>
      <b-popover :target="'date-subtask-controls-' + subtask.id.toString()" :show.sync="popoverShow[subtask.id]" triggers="focus" variant="primary" placement="left">
        <div class="d-flex flex-column">
          <button @click="updateTaskPriority(subtask, 'Critical')" class="mb-25" style="padding:5px 25px;  border:none" :style="{ backgroundColor: getSectionBgColor('Critical'), color: getSectionColor('Critical') }">Critical</button>
          <button @click="updateTaskPriority(subtask, 'High')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('High'), color: getSectionColor('High') }">High</button>
          <button @click="updateTaskPriority(subtask, 'Medium')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('Medium'), color: getSectionColor('Medium') }">Medium</button>
          <button @click="updateTaskPriority(subtask, 'Low')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('Low'), color: getSectionColor('Low') }">Low</button>
          <button @click="updateTaskPriority(subtask, 'Not a Priority')" class="mb-25" style="padding:5px 25px; border:none" :style="{ backgroundColor: getSectionBgColor('Not a Priority'), color: getSectionColor('Not a Priority') }">Not a Priority</button>
        </div>
      </b-popover>
    </div> -->
                          </div>
                        </div>
                        <!-- SUB TASK FINISHEDDD -->
                      </div>

                      <div class="task-content add-cell" @click="handleTaskAdd()">
                        <div class="w-3"></div>

                        <div class="w-97 d-flex justify-content-start">
                          <div class="d-flex align-items-center">
                            <i class="fa-regular fa-plus mr-1"></i>

                            <span>Add new task...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="$Can('projects_notes')" class="cardClass" style="background-color: #f3f3f3;">
                    <div class="subTitle">Comments</div>

                    <notes-component v-if="projectModalId != null" :project-modal-id="projectModalId" :project-comments="projectData.comments"></notes-component>
                  </div>
                </div>
              </div>
              <div v-if="activeTab == 2">
                <div class="cardClass">
                  <div class="subTitle">Files</div>

                  <b-overlay v-if="projectModalId != null" :show="loading" rounded="sm" no-fade>
                    <div>
                      <div class="d-flex justify-content-end">
                        <b-button-group>
                          <b-button @click="newFolder" size="sm" variant="outline-primary">
                            New Folder
                          </b-button>
                          <b-button @click="uploadFiles" size="sm" variant="outline-primary">
                            Upload Files
                          </b-button>
                        </b-button-group>
                      </div>

                      <div id="breadcrumb-alignment" class="mb-1" v-if="foldersData.length > 0">
                        <div id="menu" class="d-flex flex-column align-items-left">
                          <b-breadcrumb>
                            <b-breadcrumb-item v-for="(item, i) in folderTemp" :key="i" :class="item.active == true ? 'active' : ''" @click="menuClick(item)">
                              <feather-icon icon="HomeIcon" class="mr-50 mb-25" v-if="i == 0" />
                              {{ item.text }}
                            </b-breadcrumb-item>
                          </b-breadcrumb>
                        </div>
                      </div>

                      <div id="breadcrumb-alignment" class="mb-1 no-file-message" v-else>
                        No Files
                      </div>

                      <div v-if="foldersData.length > 0">
                        <div class="custom-grid">
                          <div v-for="x in foldersData" v-if="x.folderId == folderId" :key="x.type + x.id" class="custom-card">
                            <div @click="itemClick(x)" class="custom-card-content">
                              <img :src="x.type == 'folder' ? '/folder.png' : '/file.png'" :alt="x.type" class="custom-card-image" />
                              <div class="custom-card-footer" v-if="x.type == 'folder'">
                                {{ truncateText(x.folderName, 64) }}
                              </div>
                              <div class="custom-card-footer" v-if="x.type == 'file'">
                                {{ truncateText(x.fileName, 64) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Add New Folder -->
                      <b-modal id="modal-new-folder" @ok="handleFolderOk" @show="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Save Folder" centered title="Add New Folder">
                        <validation-observer ref="folderRules">
                          <b-form>
                            <b-form-group>
                              <label for="folderName">Folder Name:</label>
                              <validation-provider name="Folder Name" #default="{ errors }" rules="required">
                                <b-form-input id="folderName" type="text" v-model="modalFolderName" placeholder="Folder Name" />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-form>
                        </validation-observer>
                      </b-modal>

                      <!-- Add New Files -->
                      <b-modal id="modal-new-file" @ok="handleFileOk" @show="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Save File" centered title="Add New Files">
                        <validation-observer ref="fileRules">
                          <b-form>
                            <b-form-group>
                              <validation-provider name="File" #default="{ errors }" rules="required">
                                <b-form-file type="file" v-model="modalFiles" multiple placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-form>
                        </validation-observer>

                        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                        </b-col>
                      </b-modal>

                      <!-- Edit Folder  -->

                      <b-modal id="modal-edit-folder" @ok="handleFolderUpdate" @hidden="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Update Folder" centered title="Edit Folder">
                        <validation-observer ref="folderRules">
                          <b-form>
                            <b-form-group>
                              <label for="folderName">Folder Name:</label>
                              <validation-provider name="Folder Name" #default="{ errors }" rules="required">
                                <b-form-input id="folderName" type="text" v-model="modalFolderName" placeholder="Folder Name" />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-form>
                        </validation-observer>
                      </b-modal>

                      <!-- Edit File Name -->
                      <b-modal id="modal-edit-file" @ok="handleFileUpdate" @show="resetModal" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" header-bg-variant="primary" size="sm" ok-title="Update File" centered title="Edit New Files">
                        <validation-observer ref="fileRules">
                          <b-form>
                            <b-form-group>
                              <validation-provider name="File Name" #default="{ errors }" rules="required">
                                <b-form-input id="fileName" type="text" v-model="modalFileName" placeholder="File Name" />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-form>
                        </validation-observer>
                      </b-modal>
                    </div>
                  </b-overlay>
                </div>
              </div>

              <div v-if="activeTab == 3">
                <div class="cardClass">
                  <div class="mt-3 text-center">
                    <h2>{{ clientName }}</h2>
                    <b-button v-if="$Can('client_details_btn')" variant="primary" @click="navigateClient">
                      <span class="text-nowrap">Access Client</span>
                    </b-button>
                  </div>
                </div>

                <div class="cardClass" v-if="$Can('client_retainer_tab')">
                  <div class="subTitle">Retainer Status</div>

                  <div class="description" v-if="activeUntil != null">
                    <div class="row">
                      <b-col cols="12" xl="6" md="12" sm="12">
                        <input disabled class="dataRange" type="text" :value="rangeDate" />
                      </b-col>

                      <b-col cols="12" xl="6" lg="6" md="12" sm="12">
                        <b-alert variant="secondary" show>
                          <div class="alert-body">
                            <span class="text-center h5"><strong>Retainer Active Until: </strong> {{ activeUntil }}</span>
                          </div>
                        </b-alert>
                      </b-col>
                    </div>

                    <div class="row">
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-alert variant="success" class="mt-1" show>
                          <div class="alert-body d-flex justify-content-between">
                            <span class="h5"><strong> Month Remaining: </strong>{{ monthRemaining }}</span>
                            <span class="h5"><strong> Next 3 Months Remaining: </strong>{{ nextRemaining }}</span>
                          </div>
                        </b-alert>
                      </b-col>
                    </div>
                  </div>
                  <div class="description" v-else>
                    <h5>There are no active retainers associated with this client.</h5>
                  </div>
                </div>

                <div class="cardClass" v-if="$Can('projects_reports')">
                  <div class="subTitle mt-2">Project Report</div>

                  <div class="description">
                    <reports-component :project-clockify-id="projectData.clockifyId" :client-clockify-id="projectData.clientClockifyId"></reports-component>
                  </div>
                </div>

                <div class="cardClass" v-if="$Can('client_projects_tab')">
                  <div class="mt-2 subTitle">Client Projects</div>
                  <div class="description">
                    <projects-component :client-id="projectData.clientId"></projects-component>
                  </div>
                </div>
                <div class="mt-3"></div>

                <div class="cardClass" v-if="$Can('client_notes_tab')">
                  <div class="mt-2 subTitle">Client Notes</div>
                  <div class="cardClass mb-2" v-for="(note, index) in clientNotes" :key="index">
                    <div class="note-header">
                      <div class="d-flex w-100 flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                        <div>
                          <h6>{{ note.fullName }}</h6>
                        </div>
                        <div>
                          <small class="text-muted" v-b-tooltip.hover.top="formatDateTime(note.created_at)">{{ formatTimeAgo(note.created_at, note.updated_at) }}</small>
                        </div>
                      </div>
                    </div>

                    <div class="notes">
                      <div class="notes-preview">
                        <div v-html="note.note || 'No Note'"></div>
                      </div>

                      <div class="note-footer">
                        <!-- <div class="note-date">{{ new Date(note.created_at).toLocaleString() }}</div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="cardClass">
                  <div class="subTitle">Team Roles</div>
                  <div class="row">
                    <div class="col-lg-3 col-md-4 col-sm-6 person">
                      <b-button variant="none" class="text-center person-details" v-b-modal.modal-add-member>
                        <div style="font-size:1.2rem"><i class="fa-solid fa-plus"></i> Add Member</div>
                      </b-button>
                    </div>
  
                    <div class="col-lg-3 col-md-4 col-sm-6 person" v-for="(role, index) in projectData.roles" :key="index">
                      <div class="profile-photo">
                        <span class="initials">{{ getAvatarText(role.userTitle) }}</span>
                      </div>
                      <b-button variant="none" class="text-left person-details p-0 m-0" :id="'team-roles-controls-' + index">
                        <div class="name">{{ role.userTitle }}</div>
                        <div class="position">{{ role.userRole ? role.userRole : '+ Add Role' }}</div>
                        <div class="close-icon"><i class="fa-solid fa-angle-down"></i></div>
                      </b-button>
  
                      <b-popover :target="'team-roles-controls-' + index" triggers="focus" variant="primary" placement="rightbottom">
                        <div class="d-flex flex-column ">
                          <span class="popoverMenu" @click="showChangeRoleModal(role.userId, role.userTitle, role.userRole)" v-if="role.userRole != null"> Change Role </span>
                          <span @click="showAddRoleModal(role.userId, role.userTitle)" class="popoverMenu" v-else> Add Role </span>
  
                          <span @click="removeProjectRole(role.userId)" class="text-danger popoverMenu">
                            Remove from project
                          </span>
                        </div>
                      </b-popover>
                    </div>
                  </div>
                </div>
  
                <div class="cardClass">
                  <div class="subTitle">Contacts</div>
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-6 contact">
                      <b-button variant="none" class="text-center contact-details" v-b-modal.modal-add-contact>
                        <div style="font-size:1.2rem"><i class="fa-solid fa-plus"></i> Add Contact</div>
                      </b-button>
                    </div>
  
                    <div class="col-lg-4 col-md-4 col-sm-6 contact" v-for="(contact, index) in projectData.contacts" :key="index">
                  
                      <b-button variant="none" class="text-left contact-details p-0 m-0" :id="'contacts-controls-' + index">
                        <div class="name">{{ contact.contactTitle }}</div>
                        <div class="position">{{ contact.contactMail }}</div>
                        <div class="position">{{ contact.contactPhone }}</div>
  
                        <div class="position">{{ contact.contactRole ? contact.contactRole : '+ Add Role' }}</div>
                        <div class="contact-icon"><i class="fa-solid fa-angle-down"></i></div>
                      </b-button>
  
                      <b-popover :target="'contacts-controls-' + index" triggers="focus" variant="secondary" placement="rightbottom">
                        <div class="d-flex flex-column ">
                          <span class="popoverMenu" @click="showChangeContactModal(contact.contactId, contact.contactTitle, contact.contactRole)" v-if="contact.contactRole != null"> Change Role </span>
                          <span @click="showAddContactModal(contact.contactId, contact.contactTitle)" class="popoverMenu" v-else> Add Role </span>
  
                          <span @click="removeProjectContact(contact.contactId)" class="text-danger popoverMenu">
                            Remove from project
                          </span>
                        </div>
                      </b-popover>
                    </div>
                  </div>
                </div> -->
            </b-card>
          </b-col>
        </b-row>

        <todo-task-handler-sidebar
          @handle-task-click="handleTaskClick"
          @update-task-is-completed="updateTaskIsCompleted"
          @update-task-due-date="updateTaskDueDate"
          v-model="isTaskHandlerSidebarActive"
          :modal-task-data.sync="tempTaskData"
          :task-filters="taskFilters"
          :blank-temp-task-data="blankTempTaskData"
          @emit-projects-update="emitProjectsUpdate"
        />

        <add-member-modal :userProp="users" @postUserData="getUserData"></add-member-modal>

        <add-contact-modal :contactProp="contacts" @postContactData="getContactData"></add-contact-modal>

        <b-modal
          id="modal-add-change-user-role"
          @ok="updateUserRole()"
          @hidden="clearData"
          :no-close-on-backdrop="true"
          :no-close-on-esc="true"
          :ok-only="true"
          cancel-variant="outline-secondary"
          header-bg-variant="primary"
          size="sm"
          ok-title="Save"
          centered
          :title="'What is ' + selectedUserTitle + '\'s role on this project?'"
        >
          <b-form>
            <b-form-group>
              <b-form-input id="memberRole" placeholder="Role Name" v-model="tempMemberRole" />
            </b-form-group>
          </b-form>
        </b-modal>

        <b-modal
          id="modal-add-change-contact-role"
          @ok="updateContactRole()"
          @hidden="clearData"
          :no-close-on-backdrop="true"
          :no-close-on-esc="true"
          :ok-only="true"
          cancel-variant="outline-secondary"
          header-bg-variant="primary"
          size="sm"
          ok-title="Save"
          centered
          :title="'What is ' + selectedUserTitle + '\'s role on this project?'"
        >
          <b-form>
            <b-form-group>
              <b-form-input id="memberRole" placeholder="Role Name" v-model="tempContactRole" />
            </b-form-group>
          </b-form>
        </b-modal>
      </b-overlay>
    </div>
  </b-sidebar>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
import { VBPopover, BSidebar } from 'bootstrap-vue';
import projectStoreModule from './projectStoreModule';
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue';
import { formatDate, getAvatarText } from '../helpers';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { quillEditor } from 'vue-quill-editor';
import vSelect from 'vue-select';
import AddMemberModal from './AddMemberModal.vue';
import AddContactModal from './AddContactModal.vue';
import FilesComponent from './FilesComponent.vue';
import NotesComponent from './NotesComponent.vue';
import ReportsComponent from './ReportsComponent.vue';
import SettingsComponent from './SettingsComponent.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormDatepicker } from 'bootstrap-vue';
import VueContext from 'vue-context';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import axiosIns from '@/libs/axios';
import ProjectsComponent from './ProjectsComponent.vue';
import { formatTimeAgo, formatDateTime } from '../helpers';

export default {
  components: {
    TodoTaskHandlerSidebar,
    ToastificationContent,
    quillEditor,
    AddMemberModal,
    AddContactModal,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    FilesComponent,
    NotesComponent,
    ReportsComponent,
    SettingsComponent,
    BSidebar,
    VueContext,
    AppCollapse,
    AppCollapseItem,
    ProjectsComponent,
  },

  directives: {
    'b-popover': VBPopover,
  },

  model: {
    prop: 'isProjectSidebarActive',
    event: 'update:is-project-sidebar-active',
  },

  props: {
    isProjectSidebarActive: {
      type: Boolean,
      required: true,
    },
    projectModalId: {
      type: Number,
      required: true,
    },
    deckOptions: {
      type: Array,
      required: true,
    },
  },

  data() {
    const descriptionOption = {
      modules: {
        toolbar: '#description-toolbar',
      },
      placeholder: 'What is this task about?',
    };

    const blankTempTaskData = {
      id: null,
      assignedUser: null,
      additionalAssigneeUser: null,
      dueDate: null,
      description: null,
      projectId: parseInt(this.projectModalId),
    };

    const tempTaskData = JSON.parse(JSON.stringify(blankTempTaskData));

    const tempProjectId = parseInt(this.projectModalId);
    return {
      popoverShow: {},
      selectedUserId: null,
      selectedUserTitle: null,
      initialDescription: null,
      selectedContactId: null,
      selectedContactTitle: null,

      tempMemberRole: null,
      tempContactRole: null,
      users: [],
      contacts: [],
      clientNotes: [],
      clientName: null,
      rangeDate: null,
      monthlyRetainerRecords: null,
      activeUntil: null,
      monthRemaining: null,
      nextRemaining: null,

      editedDescription: null,
      descriptionOption,
      isNameEditing: false,
      isDescriptionEditing: false,
      loading: true,
      activeTab: 1,

      isTaskHandlerSidebarActive: false,
      projectData: null,
      blankTempTaskData,
      tempTaskData,

      taskFilters: [
        { title: 'Critical', textColor: '#000000', bgColor: '#f06a6a' },
        { title: 'High', textColor: '#000000', bgColor: '#ec8d71' },
        { title: 'Medium', textColor: '#000000', bgColor: '#f1bd6c' },
        { title: 'Low', textColor: '#000000', bgColor: '#b3df97' },
        { title: 'Not a Priority', textColor: '#FFFFFF', bgColor: '#4573d2' },
      ],

      rightClickData: [],
      fileURL: store.state.app.fileURL,

      folderId: 0,
      modalFolderName: null,
      modalFiles: null,
      modalFile: [],
      percentCompleted: 0,
      userData: JSON.parse(localStorage.getItem('userData')),
      directoryPath: '',
      projectId: tempProjectId,
      folderTemp: [
        {
          text: 'Main',
          id: 0,
          active: true,
          projectId: tempProjectId,
        },
      ],

      foldersData: [],
    };
  },

  mounted() {
    this.getProjectById();
    this.getUsers();
  },
  watch: {
    isTaskHandlerSidebarActive: function(newVal, oldVal) {
      if (!newVal) {
        this.getProjectById();
      }
    },
  },

  methods: {
    formatTimeAgo,
    formatDateTime,
    formatDate,
    getAvatarText,

    handleClickName() {
      if (this.$Can('projects_edit')) {
        this.isNameEditing = true;
      }
    },

    handleClickDescription(projectData) {
      if (this.$Can('projects_edit')) {
        this.isDescriptionEditing = true;
        this.editedDescription = projectData.description;
      }
    },

    navigateClient() {
      router.push({ name: 'client-preview', params: { id: this.projectData.clientId } });
    },
    emitProjectsUpdate() {
      this.$emit('emit-get-projects');
    },

    updateTaskDueDate(taskId, dueDate) {
      this.loading = true;

      store
        .dispatch('project/updateTaskDueDate', {
          id: taskId,
          dueDate: dueDate,
        })
        .then((res) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Task Due date has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateTaskPriority(task, priority) {
      this.popoverShow[task.id] = false;
      task.priority = priority;
      this.loading = true;

      store
        .dispatch('project/updateTaskPriority', {
          id: task.id,
          priority: priority,
        })
        .then((res) => {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Task Priority has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateTaskAssigneUser(task, assignedUser) {
      task.userTitle = assignedUser.fullName;
      task.assignedUser = assignedUser.id;
      this.loading = true;

      store
        .dispatch('project/updateTaskAssigneUser', {
          id: task.id,
          assignedUser: assignedUser.id,
        })
        .then((res) => {
          this.loading = false;

          const successText = task.isCompleted ? '✔️ Task has been marked as completed.' : '⭕ Task has been marked as not completed.';

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: successText,
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateTaskIsCompleted(task) {
      task.isCompleted = !task.isCompleted;
      this.loading = true;
      console.log(task);
      store
        .dispatch('project/updateTaskIsCompleted', {
          id: task.id,
          isCompleted: task.isCompleted,
        })
        .then((res) => {
          this.$emit('emit-get-projects');
          this.loading = false;

          const successText = task.isCompleted ? '✔️ Task has been marked as completed.' : '⭕ Task has been marked as not completed.';

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: successText,
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    getUserData(val) {
      this.projectData.roles.push(val);

      this.updateProjectRoles();
    },

    getContactData(val) {
      console.log(val);
      this.projectData.contacts.push(val);
      this.updateProjectContacts();
    },

    showAddRoleModal(userId, userTitle) {
      this.selectedUserId = userId;
      this.selectedUserTitle = userTitle;
      this.$bvModal.show('modal-add-change-user-role');
    },

    showChangeRoleModal(userId, userTitle, userRole) {
      this.selectedUserId = userId;
      this.tempMemberRole = userRole;
      this.selectedUserTitle = userTitle;
      this.$bvModal.show('modal-add-change-user-role');
    },

    showAddContactModal(contactId, contactTitle) {
      this.selectedContactId = contactId;
      this.selectedContactTitle = contactTitle;
      this.$bvModal.show('modal-add-change-contact-role');
    },

    showChangeContactModal(contactId, contactTitle, contactRole) {
      this.selectedContactId = contactId;
      this.tempContactRole = contactRole;
      this.selectedContactTitle = contactTitle;
      this.$bvModal.show('modal-add-change-contact-role');
    },

    updateUserRole() {
      this.projectData.roles.forEach((role) => {
        if (role.userId == this.selectedUserId) {
          role.userRole = this.tempMemberRole;
        }
      });

      this.loading = true;

      store
        .dispatch('project/updateProjectRoles', {
          id: this.projectData.id,
          roles: this.projectData.roles,
        })
        .then((res) => {
          this.$bvModal.hide('modal-add-change-user-role');
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Project Roles has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateContactRole() {
      this.projectData.contacts.forEach((contact) => {
        if (contact.contactId == this.selectedContactId) {
          contact.contactRole = this.tempContactRole;
        }
      });

      this.loading = true;

      store
        .dispatch('project/updateProjectContacts', {
          id: this.projectData.id,
          contacts: this.projectData.contacts,
        })
        .then((res) => {
          this.$bvModal.hide('modal-add-change-contact-role');
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Project Contacts has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    clearData() {
      this.tempMemberRole = null;
    },

    getProjectById() {
      const _id = parseInt(this.projectModalId);

      store
        .dispatch('project/getProjectById', { id: _id })
        .then((res) => {
          this.projectData = res.data;
          this.foldersData = this.projectData.folders;
          this.projectContactClient();

          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateProjectName() {
      if (this.initialDescription !== this.projectData.name) {
        this.loading = true;
        this.isNameEditing = false;
        store
          .dispatch('project/updateProjectName', {
            id: this.projectData.id,
            name: this.projectData.name,
          })
          .then((res) => {
            this.$emit('emit-get-projects');
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Project Name has been updated',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.isNameEditing = false;
      }
    },

    updateProjectNameEnter() {
      this.isNameEditing = false;
    },

    updateProjectDescription() {
      this.loading = true;

      store
        .dispatch('project/updateProjectDescription', {
          id: this.projectData.id,
          description: this.editedDescription,
        })
        .then((res) => {
          this.projectData.description = this.editedDescription;
          this.loading = false;
          this.isDescriptionEditing = false;
          this.$emit('emit-get-projects');
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Project Description has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateProjectDescriptionEnter() {
      this.isDescriptionEditing = false;
    },

    updateProjectRoles() {
      this.loading = true;

      store
        .dispatch('project/updateProjectRoles', {
          id: this.projectData.id,
          roles: this.projectData.roles,
        })
        .then((res) => {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Project Roles has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    handleDeckChange(newStatus) {
      this.loading = true;
      this.projectData.status = newStatus;
      store
        .dispatch('project/updateProjectStatus', {
          id: this.projectData.id,
          status: this.projectData.status,
        })
        .then((res) => {
          this.$emit('emit-get-projects');
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Project Status has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    updateProjectContacts() {
      this.loading = true;

      store
        .dispatch('project/updateProjectContacts', {
          id: this.projectData.id,
          contacts: this.projectData.contacts,
        })
        .then((res) => {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Project Contacts has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    cancelDescriptionEdit() {
      this.isDescriptionEditing = false;
      this.editedDescription = this.projectData.description;
    },

    removeProjectRole(userId) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this member?', {
          size: 'sm',
          title: 'Confirm Deletion',
          okVariant: 'primary',
          headerBgVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;

            const index = this.projectData.roles.findIndex((role) => role.userId === userId);
            if (index !== -1) {
              this.projectData.roles.splice(index, 1);

              this.updateProjectRoles();
            } else {
              this.loading = false;
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    removeProjectContact(contactId) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this contact?', {
          size: 'sm',
          title: 'Confirm Deletion',
          okVariant: 'primary',
          headerBgVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            console.log(this.projectData);
            console.log(contactId);
            const index = this.projectData.contacts.findIndex((contact) => contact.contactId === contactId);
            console.log(index);
            if (index !== -1) {
              this.projectData.contacts.splice(index, 1);
              console.log('11');
              this.updateProjectContacts();
            } else {
              this.loading = false;
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    getSectionColor(val) {
      const section = this.taskFilters.find((section) => section.title === val);
      return section ? section.textColor : '#FFFFFF';
    },

    getSectionBgColor(val) {
      const section = this.taskFilters.find((section) => section.title === val);
      return section ? section.bgColor : '#000000';
    },

    toggleExpand(task) {
      task.isExpanded = !task.isExpanded;
    },

    handleTaskClick(val) {
      console.log(val);
      this.tempTaskData = val;
      this.isTaskHandlerSidebarActive = true;
    },

    handleTaskAdd() {
      this.tempTaskData = JSON.parse(JSON.stringify(this.blankTempTaskData));
      this.isTaskHandlerSidebarActive = true;
    },

    getUsers() {
      store
        .dispatch('project/fetchUsers', [])
        .then((res) => {
          this.users = res.data;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    projectContactClient() {
      if (this.projectData.clientId != null) {
        store
          .dispatch('project/projectContactClient', { id: this.projectData.clientId })
          .then((res) => {
            this.contacts = res.data.contacts;
            this.clientNotes = res.data.notes;
            this.clientName = res.data.clientName;

            if (this.rangeDate == null) {
              this.rangeDate = `${res.data.startDate} to ${res.data.endDate}`;
            }

            this.monthlyRetainerRecords = res.data.retainers;
            this.activeUntil = res.data.activeUntil;
            this.monthRemaining = res.data.monthRemaining;
            this.nextRemaining = res.data.nextRemaining;
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },

    archiveProject(val) {
      this.loading = true;
      this.projectData.archived = val;
      store
        .dispatch('project/archiveProject', {
          id: this.projectData.id,
          archived: this.projectData.archived,
        })
        .then((res) => {
          this.loading = false;
          if (val) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Project has been archived.',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Project has been activated.',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    clearSidebarData() {
      this.$emit('update:is-project-sidebar-active', false);
      this.$emit('update:project-modal-id', null);
      if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME);
    },

    getSectionColor(val) {
      const section = this.taskFilters.find((section) => section.title === val);
      return section ? section.textColor : '#FFFFFF';
    },

    getSectionBgColor(val) {
      const section = this.taskFilters.find((section) => section.title === val);
      return section ? section.bgColor : '#000000';
    },

    getStatusBgColor(val) {
      const section = this.deckOptions.find((section) => section.name === val);
      return section ? section.bgColor : '#000000';
    },

    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      } else {
        return text;
      }
    },

    newFolder() {
      this.$bvModal.show('modal-new-folder');
    },

    uploadFiles(val) {
      this.$bvModal.show('modal-new-file');
    },

    handleClick(event, type, item) {
      if (type == 'folder') {
        this.rightClickData = [
          { icon: 'FileIcon', text: 'Open', data: item },
          { icon: 'EditIcon', text: 'Edit', data: item },
          { icon: 'XIcon', text: 'Delete', data: item },
        ];
        this.$refs.rightClicks.open(event, item);
      } else if (type == 'file') {
        this.rightClickData = [
          { icon: 'EditIcon', text: 'Edit', data: item },
          { icon: 'XIcon', text: 'Delete', data: item },
          { icon: 'SaveIcon', text: 'Save', data: item },
        ];
        this.$refs.rightClicks.open(event, item);
      }
    },

    menuClick(val) {
      this.folderId = val.id;

      if (val.id == 0) {
        this.folderTemp = [
          {
            text: 'Main',
            id: 0,
            active: true,
            companyId: -1,
          },
        ];
      } else {
        var tempFolder = [];
        var finished = false;
        this.folderTemp.forEach((element) => {
          if (finished == false) {
            if (element.id == val.id) {
              tempFolder.push({
                text: element.text,
                id: element.id,
                active: true,
                companyId: element.companyId,
              });
              this.folderTemp = tempFolder;
              finished = true;
            } else {
              tempFolder.push(element);
            }
          }
        });
      }
    },

    optionClicked(val) {
      switch (val.text) {
        case 'Open':
          this.itemClick(val.data);
          break;

        case 'Edit':
          if (val.data.type == 'folder') {
            console.log(val);
            this.modalFolderName = val.data.folderName;
            this.selectFolderId = val.data.id;

            this.$bvModal.show('modal-edit-folder');
          } else {
            this.modalFileName = val.data.fileName;
            this.selectFileId = val.data.id;

            this.$bvModal.show('modal-edit-file');
          }

          break;

        case 'Save':
          // const url = window.URL.createObjectURL(new Blob([this.fileURL + val.data.fileLink]));
          this.loading = true;
          this.itemClick(val.data);

          // const url = fetch(this.fileURL + val.data.fileLink).then((response) => response.blob());

          // window.location.href = this.fileURL + val.data.fileLink;
          break;

        case 'Delete':
          if (val.data.type == 'file') {
            this.$bvModal
              .msgBoxConfirm('Are you sure you want to delete this file?', {
                size: 'sm',
                title: 'Confirm Deletion',
                okVariant: 'primary',
                headerBgVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: true,
                centered: true,
              })
              .then((value) => {
                if (value) {
                  this.loading = true;

                  axiosIns
                    .delete(`deleteProjectFile/${val.data.id}`)
                    .then((res) => {
                      this.foldersData = res.data;
                      this.totalRows = res.data.length;

                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Successful',
                          text: '✔️ File has been deleted',
                          icon: 'ThumbsUpIcon',
                          variant: 'success',
                        },
                      });
                      this.loading = false;
                    })
                    .catch((error) => {
                      console.log('error delete ', error);
                      this.loading = false;
                    });
                }
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          } else {
            this.$bvModal
              .msgBoxConfirm('Are you sure you want to delete this folder?', {
                size: 'sm',
                title: 'Confirm Deletion',
                okVariant: 'primary',
                headerBgVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: true,
                centered: true,
              })
              .then((value) => {
                if (value) {
                  this.loading = true;

                  axiosIns
                    .delete(`deleteProjectFolder/${val.data.id}`)
                    .then((res) => {
                      this.foldersData = res.data;

                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Successful',
                          text: '✔️ Folder has been deleted',
                          icon: 'ThumbsUpIcon',
                          variant: 'success',
                        },
                      });

                      this.loading = false;
                    })
                    .catch((error) => {
                      this.loading = false;
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'An error occurred',
                          text: 'Please try again later or contact support.',
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                        },
                      });
                    });
                }
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }

          break;

        default:
          break;
      }
    },

    itemClick(val) {
      if (val.type == 'folder') {
        this.folderTemp.forEach((element) => {
          element.active = false;
        });
        this.folderTemp.push({
          id: val.id,
          text: val.folderName,
          active: true,
          companyId: val.companyId,
        });

        this.topMenuId = val.id;
        this.folderId = val.id;
      } else if (val.type == 'file') {
        this.directoryPath = '';
        this.folderTemp.forEach((element) => {
          this.directoryPath += element.text + '/';
        });

        axiosIns
          .post('downloadProjectFile', { link: val.fileLink }, { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));

            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', val.fileLink);

            document.body.appendChild(link);
            link.click();
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
    },

    handleFolderOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      this.$refs.folderRules.validate().then((success) => {
        if (success) {
          var tempData = {
            folderName: this.modalFolderName,
            topMenuId: this.folderId,
            projectId: this.projectId,
            status: 'A',
          };

          this.loading = true;

          store
            .dispatch('project/saveProjectFolder', tempData)
            .then((res) => {
              this.foldersData = res.data;

              this.$bvModal.hide('modal-new-folder');
              this.loading = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Folder has been created',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
            })
            .catch((error) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'An error occurred',
                  text: 'Please try again later or contact support.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    handleFolderUpdate(bvModalEvent) {
      // Prevent modal from closing

      bvModalEvent.preventDefault();

      var data = {
        id: this.selectFolderId,
        folderName: this.modalFolderName,
        topMenuId: this.folderId,
        status: 'A',
      };
      // Trigger submit handler

      this.modalFolderUpdate(data);
    },

    handleFileUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      var data = {
        id: this.selectFileId,
        fileName: this.modalFileName,
        status: 'A',
      };
      // Trigger submit handler

      this.modalFileUpdate(data);
    },

    handleFileOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      this.$refs.fileRules.validate().then((success) => {
        if (success) {
          if (this.percentCompleted == 0) {
            this.directoryPath = '';
            this.folderTemp.forEach((element) => {
              this.directoryPath += element.text + '/';
            });
            this.loading = true;
            this.uploadFilesSequentially(0);
          }
        }
      });
    },

    modalFileOk(tempData) {
      store
        .dispatch('project/saveProjectFile', tempData)
        .then((res) => {
          this.foldersData = res.data;

          this.$bvModal.hide('modal-new-file');
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ File has been uploaded',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    uploadFilesSequentially(index) {
      if (index < this.modalFiles.length) {
        let element = this.modalFiles[index];
        let formData = new FormData();

        formData.append('file', element);
        formData.append('path', this.directoryPath);

        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * (100 - (this.modalFiles.length + 1))) / progressEvent.total);
          }.bind(this),
        };

        axiosIns
          .post('uploadProjectFile', formData, config)
          .then((response) => {
            this.modalFile.push(response.data);
            if (index === this.modalFiles.length - 1) {
              this.percentCompleted = 100;
            }

            if (this.percentCompleted === 100) {
              var data = {
                topMenuId: this.folderId,
                fileDetails: {
                  fileName: this.modalFile,
                  fileLink: this.directoryPath,
                },

                projectId: this.projectId,
                status: 'A',
              };

              this.modalFileOk(data);
            }

            this.uploadFilesSequentially(index + 1);
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
    },

    modalFolderUpdate(data) {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-edit-folder');
      });

      this.loading = true;

      store
        .dispatch('project/updateProjectFolder', data)
        .then((res) => {
          this.foldersData = res.data;
          this.modalFolderName = null;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    modalFileUpdate(data) {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-edit-file');
      });

      this.loading = true;

      store
        .dispatch('project/updateProjectFile', data)
        .then((res) => {
          this.foldersData = res.data;
          this.modalFileName = null;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    resetModal() {
      this.modalFolderName = null;
      this.modalFiles = null;
    },

    fileSelected(event) {
      this.directoryPath = '';
      this.folderTemp.forEach((element) => {
        this.directoryPath += element.text + '/';
      });

      var total = event.length;
      event.forEach((element, i) => {
        let formData = new FormData();

        formData.append('file', element);
        formData.append('path', this.directoryPath);

        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * (100 - (event.length + 1))) / progressEvent.total);
          }.bind(this),
        };

        axiosIns
          .post('uploadProjectFile', formData, config)
          .then((response) => {
            this.modalFile.push(response.data);

            total = total - 1;

            if (total == 0) {
              this.percentCompleted = 100;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
span.activeMenu {
  font-weight: 700;
  color: black;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px #6d6e6f;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.passiveMenu {
  font-weight: 400;
  color: #6d6e6f;
  padding-bottom: 2px;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.activeMenu:hover {
  background-color: #f9f8f8;
  padding: 5px 10px 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

span.passiveMenu:hover {
  background-color: #f9f8f8;
  box-shadow: inset 0 -2px #afabac;
  padding: 5px 10px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

.projectTitle {
  font-size: 1.75rem;
  font-weight: 400;
  transition: padding 0.3s;
  border: solid 1px transparent;
  border-radius: 5px;
}

.projectTitle:hover {
  border-color: #ccc;
  background-color: #f9f8f8;
  cursor: pointer;
}

.subTitle {
  font-size: 1rem;
  margin-bottom: 0.25rem;
  font-weight: 700;
}

.person {
  display: flex;
  align-items: center;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  height: 110px;
  max-width: 300px;
  min-width: 200px;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 10px;
}

.person:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.contact {
  display: flex;
  align-items: center;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  height: 110px;
  max-width: 300px;
  min-width: 200px;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 10px;
}

.contact:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.contact-details {
  flex-grow: 1;
}

.description {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;

  // background-color: #00000003;
}

.description:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: #ccc;
}

.profile-photo {
  width: 42px;
  height: 42px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: white;
}

.initials {
  text-transform: uppercase;
}

.person-details {
  flex-grow: 1;
}

.name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.position {
  color: #6d6e6f;
  margin-top: 10px;
}
.close-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.person:hover .close-icon {
  opacity: 1;
}

.contact-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;
  color: black;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.contact:hover .contact-icon {
  opacity: 1;
}

/* TO - DO   */

.todo-header {
  display: flex;
  background-color: #f5f5f5;
}

.header-cell {
  padding: 7px;
  border: 1px solid #e5e5e5;
  font-weight: bold;
}

.task-content {
  display: flex;
  width: 100%;
}

.subtask-content {
  display: flex;
  width: 100%;
  left: 30px;
}

.todo-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.add-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  background-color: #dddddd20;
}

.add-cell:hover {
  background-color: #dddddd40;
  cursor: pointer;
}

.todo-cell:last-child {
  border-right: 1px solid #e5e5e5;
}

.task-name {
  display: flex;
  align-items: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-checkbox {
  position: relative;
}

.custom-checkbox .checkbox-icon {
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
}

.custom-checkbox .checkbox-icon:not(.checked)::before {
  content: '\2713';
  font-size: 10px;
  color: #000;
}

.custom-checkbox .checkbox-icon.checked {
  background-color: #198754;
}

.custom-checkbox .checkbox-icon.checked::before {
  content: '\2713';
  font-size: 14px;
  color: #fff;
}

.no-results {
  text-align: center;
  padding: 20px;
  display: none;
}

.no-results.show {
  display: block;
}

.w-97 {
  width: 97%;
}

.w-47 {
  width: 47%;
}
.w-45 {
  width: 45%;
}

.w-43 {
  width: 43%;
}

.w-39 {
  width: 39%;
}
.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-51 {
  width: 51%;
}
.w-55 {
  width: 55%;
}
.w-25 {
  width: 25%;
}
.w-41 {
  width: 41%;
}

.w-35 {
  width: 35%;
}

.w-12 {
  width: 12%;
}

.w-5 {
  width: 5%;
}

.w-4 {
  width: 4%;
}

.w-3 {
  width: 3%;
}
.w-8 {
  width: 8%;
}

.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}

.w-38 {
  width: 38%;
}

.hover-background:hover {
  background-color: #edeae9;
  cursor: pointer;
  position: relative;
}

.hover-background:hover::after {
  content: '\23F7';
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: #bbb;
}

.popoverMenu {
  cursor: pointer;
  padding: 0.5rem;
}

.popoverMenu:hover {
  background-color: rgba(197, 197, 197, 0.05);
  padding: 0.5rem;
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #description-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.cardClass {
  padding: 1.25rem;
  margin-bottom: 15%;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.custom-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
  gap: 5px;
  // padding: 50px;
}

.custom-card {
  // border: 1px solid #e0e0e0;
  // border-radius: 5px;
  // background-color: #f5f5f5;
  transition: background-color 0.2s ease; /* Hover efekti için geçiş ekledik */
}

.custom-card-content {
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.custom-card:hover {
  background-color: #e0e0e080; /* Hover efekti için arka plan rengi değişiyor */
}

.custom-card-image {
  width: 64px;
}

.custom-card-footer {
  font-size: 12px;
  text-align: center;
  // padding: 5px 0;
}
.active a {
  color: #6e6b7b;
  cursor: auto;
  text-decoration: none;
}

.no-file-message {
  font-size: 24px;
  color: #d8d6de;
  text-align: center;
  margin-top: 20px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~vue-context/dist/css/vue-context.css';
</style>
