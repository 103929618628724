<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <b-row>
      <b-col xl="1" lg="1" class="d-none d-lg-block">
        <div v-if="$Can('pclockify_sync')" class="d-flex align-items-center  justify-content-start">
          <b-button class="w-100" style="margin-top:0.300rem; padding-top: 0.525rem; padding-bottom: 0.525rem;" variant="outline-primary" @click="getProjectDataFromClockify" v-b-tooltip.hover.top="'Get Data From Clockify'">
            <feather-icon icon="DownloadCloudIcon" size="20" />
          </b-button>
        </div>
      </b-col>
      <b-col xl="3" lg="3" md="3" cols="6">
        <div class="d-flex align-items-center">
          <v-select v-model="selectedOption" :options="deckView ? showOptions2 : showOptions" :clearable="false" style="background-color: white;" class="w-100 m-25" @input="handleOptionChange" />
        </div>
      </b-col>

      <b-col xl="3" lg="3" md="3" cols="6">
        <div class="d-flex align-items-center">
          <v-select v-model="selectedDeckOption" :options="deckOptions" :clearable="false" :reduce="(val) => val.name" label="name" style="background-color: white;" class="w-100 m-25" @input="getProjects" />
        </div>
      </b-col>

      <b-col xl="5" lg="5" md="6" cols="12" v-if="$Can('projects_add_btn')">
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input v-model="searchText" class="d-inline-block mr-1 m-25" cle placeholder="Search..." />
          <b-button variant="primary" router-link to="/projects/project-add">
            <span class="text-nowrap"> Create Project </span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <div v-if="filteredProjects.length > 0" class="mt-50">
      <column-view v-if="deckView" :deck-options="deckOptions.filter((e) => e.name != 'All')" :filtered-projects="filteredProjects" @get-projects="getProjects"></column-view>
    </div>

    <div class="text-center text-muted" v-else>
      <h2 class="text-muted mt-5">There are no projects</h2>
    </div>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import projectStoreModule from './projectStoreModule';
import vSelect from 'vue-select';
import VueContext from 'vue-context';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BBreadcrumb } from 'bootstrap-vue';
import { formatDate, getTeamClass } from '../helpers';
import BoxView from './BoxView.vue';
import ColumnView from './ColumnView.vue';
import { debounce } from 'lodash';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    VueContext,
    BBreadcrumb,
    ToastificationContent,
    BoxView,
    ColumnView,
  },

  setup() {
    const PROJECT_APP_STORE_MODULE_NAME = 'project';
    // Register module
    if (!store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.registerModule(PROJECT_APP_STORE_MODULE_NAME, projectStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      searchText: '',
      companiesURL: store.state.app.companiesURL,
      loading: true,
      isArchive: 0,
      projects: [],
      selectedOption: 'Active',
      selectedDeckOption: 'All',
      showOptions: ['Active', 'Archived'],
      showOptions2: ['Active', 'Archived', 'Hidden'],
      deckOptions: [],
      windowHeight: window.innerHeight,
      deckView: true,
    };
  },

  mounted() {
    this.getProjects();
    this.debouncedGetWindowHeight = debounce(this.getWindowHeight, 300);
    window.addEventListener('resize', this.debouncedGetWindowHeight);
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.getWindowHeight);
    window.removeEventListener('resize', this.debouncedGetWindowHeight);
  },

  watch: {},

  methods: {
    getWindowHeight() {
      console.log(window.innerHeight);
      this.windowHeight = window.innerHeight;
    },

    formatDate,
    getTeamClass,

    getProjects() {
      this.loading = true;

      store
        .dispatch('project/getProjectBoards', [])
        .then((res) => {
          this.deckOptions = res.data;
          this.deckOptions.unshift({
            name: 'All',
            bgColor: '00000',
            color: '00000',
          });
          store
            .dispatch('project/getProjects', {
              isArchive: this.isArchive,
              isHidden: this.deckView ? this.selectedOption : null,
              selectedStatus: this.selectedDeckOption,
            })
            .then((res) => {
              this.projects = res.data;
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    handleOptionChange() {
      if (this.selectedOption === 'Active') {
        this.isArchive = 0;
        this.getProjects();
      } else if (this.selectedOption === 'Archived') {
        this.isArchive = 1;
        this.getProjects();
      } else if (this.selectedOption === 'Hidden') {
        this.getProjects();
      }
    },

    redirectPreview(_id) {
      this.$router.push({ name: 'project-preview', params: { id: _id } });
    },

    getProjectDataFromClockify() {
      this.loading = true;
      store
        .dispatch('project/getProjectDataFromClockify')
        .then((res) => {
          this.getProjects();
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
  },

  computed: {
    filteredProjects() {
      if (!this.searchText) {
        return this.projects;
      }
      const lowercaseSearchText = this.searchText.toLowerCase();

      // return this.projects.filter((project) => project.name.toLowerCase().includes(lowercaseSearchText));

      return this.projects.filter((project) => {
        const projectNameIncludesText = project.name.toLowerCase().includes(lowercaseSearchText);

        if (projectNameIncludesText) {
          return true;
        }
        if (project.client && project.client.name) {
          const clientNameIncludesText = project.client.name.toLowerCase().includes(lowercaseSearchText);
          return clientNameIncludesText;
        }
        return [];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card:hover {
  // transform: scale(1.01);
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.15);
  // cursor: pointer;
}

.card {
  background-color: #fff;
  // max-width: 360px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0rem;
  border: solid 1px #00000025;
}
.card .banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 125px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  margin: 10px 20px 0px 20px;
}

.card h2.name {
  text-align: center;
  margin: 0;
  padding: 0rem 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: normal;
  font-size: 1.225rem;
}
.card .title {
  color: #a0a0a0;
  font-size: 0.85rem;
  text-align: center;
  padding: 0 2rem 1.2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card .actions {
  padding: 0 2rem 1.2rem;
  display: flex;
  flex-direction: column;
  order: 99;
}
.card .actions .task-info {
  padding: 0 0 1rem;
  display: flex;
}
.card .actions .task-info h2 {
  text-align: center;
  width: 50%;
  margin: 0;
  box-sizing: border-box;
}
.card .actions .task-info h2 a {
  text-decoration: none;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  transition: background-color 100ms ease-in-out;
}
.card .actions .task-info h2 a span {
  color: #73c0d2;
  font-weight: bold;
  transform-origin: bottom;
  transform: scaleY(1.3);
  transition: color 100ms ease-in-out;
}
.card .actions .task-info h2 a small {
  color: #afafaf;
  font-size: 0.85rem;
}

.card .desc {
  text-align: justify;
  padding: 0 2rem 2.5rem;
  order: 100;
}

.strikethrough {
  text-decoration: line-through;
}

.custom-checkbox {
  position: relative;
}

/* Style the custom checkbox container */
.custom-checkbox .checkbox-icon {
  width: 45px;
  height: 42px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
}
/* Style the unchecked state of the checkbox icon */
.custom-checkbox .checkbox-icon:not(.checked)::before {
  font-size: 35px;
  color: #ccc; /* Gray color for the tick when unchecked */
}

/* Style the checked state of the checkbox icon */
.custom-checkbox .checkbox-icon.checked {
  background-color: #194c87; /* Green background when checked */
  color: white;
}

/* Style the tick icon (a simple checkmark) when checked */
.custom-checkbox .checkbox-icon.checked::before {
  font-size: 35px;
  color: #fff; /* White color for the tick when checked */
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~vue-context/dist/css/vue-context.css';
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
